<template>
  <div class="skip" v-if="tutorial.Ongoing">
    <v-btn
      small
      text
      class="text-lowercase my-0 py-0"
      elevation="0"
      color="grey lighten-4"
      @click="skipTutorial"
    >
      {{ $t("action.skipTutorial") }}
      <v-icon> mdi-redo </v-icon>
    </v-btn>
  </div>
</template>
  
  <script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    tutorial: (state) => state.tutorial.data,
  }),
  data: () => ({
    tutorialApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    skipTutorial() {
      this.tutorialApi.url =
        this.$api.servers.game + "/api/v1/" + this.$_getLocale() + "/game/tutorial/skip";
      this.tutorialApi.callbackReset = () => {
        this.tutorialApi.isLoading = true;
        this.tutorialApi.isError = false;
        this.tutorialApi.error = null;
      };
      this.tutorialApi.callbackError = (e) => {
        this.tutorialApi.isLoading = false;
        this.tutorialApi.isError = true;
        this.tutorialApi.error = e;
      };
      this.tutorialApi.callbackSuccess = (resp) => {
        this.tutorialApi.isLoading = false;
        this.$intro().exit();
        this.$store.commit("updateTutorial", resp);
        this.$router.go()
      };
      this.tutorialApi.params = {
        key: this.tutorial.Ongoing,
      };
      this.$api.fetch(this.tutorialApi);
    },
  },
};
</script>
  
  <style>
.skip {
  position: fixed !important;
  top: 0;
  right: 0;
  text-align: right;
  z-index: 9999999999999;
}
</style>